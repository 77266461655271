import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../../layout'
import SEO from '../../utils/seo'

import CategoryContent from '../../components/Modules/Solutions/CategoryContent'

const TechnologyPage = () => {
    const { technologyData } = useStaticQuery(graphql`
        query {
            technologyData: allStrapiTecnologia {
                edges {
                    node {
                        id
                        solucoes {
                            description
                            id
                            title
                            background {
                                publicURL
                            }
                        }
                        categories {
                            description
                            id
                            title
                            background {
                                publicURL
                            }
                        }
                    }
                }
            }
        }
    `)

    const mainTechData = technologyData.edges[0].node

    return (
        <Layout>
            <SEO title="Tecnologia" />

            <CategoryContent
                type="tecnologia"
                title={mainTechData?.solucoes?.title}
                description={mainTechData?.solucoes?.description}
                heroBackgroundImage={mainTechData?.solucoes?.background?.publicURL}
                categoriesData={mainTechData?.categories}
            />
        </Layout>
    )
}

export default TechnologyPage
